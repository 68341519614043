import React, { useEffect } from 'react';
import Layout from '../components/commons/Layout';
import styled from 'styled-components';
import theme from '../constants/theme';
import { graphql } from 'gatsby';
import ResponsiveText from '../components/typography/ResponsiveText';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Markdown from '../components/commons/Markdown';
import isLng from '../helpers/i18n';
import { useI18next } from 'gatsby-plugin-react-i18next';

const ContattiWrapper = styled.div`
  .detail-header {
    display: flex;
    flex-flow: row wrap;

    h2 {
      font-size: 30px;
      text-transform: uppercase;
    }

    h1 {
      margin-bottom: 20px;
    }
  }

  h3 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .section-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    .featured-image {
      grid-column: span 2;
      margin: 0;
      width: 100%;
    }

    ul.infos {
      li {
        padding-left: calc(21px + 1rem);
        font-family: '${theme.fontNeue}';
        font-weight: 500;
        font-size: 1.3rem;
        margin-bottom: 0.75rem;
        line-height: 1.2;
        position: relative;

        ::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 21px;
          height: 21px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }

        a {
          text-decoration: underline;
        }
      }

      li.city {
        font-family: '${theme.fontSyne}';
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2rem;

        ::before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 21' width='21' height='21'%3E%3Crect width='21' height='21' fill='${theme.colorYellow.replace(
            '#',
            '%23'
          )}'/%3E%3C/svg%3E");
        }
      }

      li.address {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;

        ::before {
          display: none;
        }
      }

      li.email::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='21' height='21'%3E%3Cpath d='M19 1H5A5.006 5.006 0 0 0 0 6V18a5.006 5.006 0 0 0 5 5H19a5.006 5.006 0 0 0 5-5V6A5.006 5.006 0 0 0 19 1ZM5 3H19a3 3 0 0 1 2.78 1.887l-7.658 7.659a3.007 3.007 0 0 1-4.244 0L2.22 4.887A3 3 0 0 1 5 3ZM19 21H5a3 3 0 0 1-3-3V7.5L8.464 13.96a5.007 5.007 0 0 0 7.072 0L22 7.5V18A3 3 0 0 1 19 21Z' fill='${theme.colorBlack.replace(
          '#',
          '%23'
        )}'/%3E%3C/svg%3E");
      }

      li.phone::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='21' height='21'%3E%3Cpath d='M22.17 1.82l-1.05-.91c-1.21-1.21-3.17-1.21-4.38 0-.03 .03-1.88 2.44-1.88 2.44-1.14 1.2-1.14 3.09 0 4.28l1.16 1.46c-1.46 3.31-3.73 5.59-6.93 6.95l-1.46-1.17c-1.19-1.15-3.09-1.15-4.28 0 0 0-2.41 1.85-2.44 1.88-1.21 1.21-1.21 3.17-.05 4.33l1 1.15c1.15 1.15 2.7 1.78 4.38 1.78 7.64 0 17.76-10.13 17.76-17.76 0-1.67-.63-3.23-1.83-4.42ZM6.24 22c-1.14 0-2.19-.42-2.91-1.15l-1-1.15c-.41-.41-.43-1.08-.04-1.51 0 0 2.39-1.84 2.42-1.87 .41-.41 1.13-.41 1.55 0 .03 .03 2.04 1.64 2.04 1.64 .28 .22 .65 .28 .98 .15 4.14-1.58 7.11-4.54 8.82-8.81 .13-.33 .08-.71-.15-1 0 0-1.61-2.02-1.63-2.04-.43-.43-.43-1.12 0-1.55 .03-.03 1.87-2.42 1.87-2.42 .43-.39 1.1-.38 1.56 .08l1.05 .91c.77 .77 1.2 1.82 1.2 2.96 0 6.96-9.77 15.76-15.76 15.76Z' fill='${theme.colorBlack.replace(
          '#',
          '%23'
        )}'/%3E%3C/svg%3E");
      }

      li.linkedin::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='21' height='21'%3E%3Cpath d='M19.333 14.667a4.66 4.66 0 0 0-3.839 2.024L8.985 13.752a4.574 4.574 0 0 0 .005-3.488l6.5-2.954a4.66 4.66 0 1 0-.827-2.643 4.633 4.633 0 0 0 .08.786L7.833 8.593a4.668 4.668 0 1 0-.015 6.827l6.928 3.128a4.736 4.736 0 0 0-.079.785 4.667 4.667 0 1 0 4.666-4.666ZM19.333 2a2.667 2.667 0 1 1-2.666 2.667A2.669 2.669 0 0 1 19.333 2ZM4.667 14.667A2.667 2.667 0 1 1 7.333 12 2.67 2.67 0 0 1 4.667 14.667ZM19.333 22A2.667 2.667 0 1 1 22 19.333 2.669 2.669 0 0 1 19.333 22Z' fill='${theme.colorBlack.replace(
          '#',
          '%23'
        )}'/%3E%3C/svg%3E");
      }
    }
  }

  .contact-form-container {
    background-color: ${theme.colorBlack};
    color: ${theme.colorWhite};
    min-height: 100vh;

    .contact-heading {
      display: flex;
      align-items: start;
      justify-content: start;

      & > div {
        flex: 1;
      }
    }

    .form-section-title {
      font-family: '${theme.fontSyne}';
      font-weight: 700;
      text-transform: uppercase;
      font-size: 2.5rem;
      line-height: 1.2;
    }

    .form-section-description {
      margin-top: 1rem;
      font-size: 1.1rem;
    }

    .form-wrapper {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 1024px) {
    .section-header {
      .featured-image {
        height: 363px;
        margin: 10px -20px;

        .gatsby-image-wrapper {
          height: 100%;
        }
      }
    }

    .section-content {
      .featured-image {
        grid-column: span 3;
      }

      .infos {
        grid-column: span 3;
      }
    }

    .contact-form-container {
      margin-top: 50px;

      .contact-heading {
        display: block;
        margin-bottom: 40px;

        br {
          display: none;
        }
      }
    }

    .grid {
      height: auto !important;
    }
  }
`;

export default function Contatti({ data }) {
  const { i18n, t } = useI18next();

  const {
    backend: {
      contactPage: {
        featuredImage,
        city,
        address,
        addressUrl,
        email,
        phone,
        linkedinUrl,
        formSectionTitleIT,
        formSectionTitleEN,
        formSectionDescriptionIT,
        formSectionDescriptionEN,
      },
      seoRules,
    },
  } = data;

  // hubspot form
  useEffect(function () {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '14542219',
          formId: '582105a2-4ea1-4c4e-a851-67a6fb2a3666',
          target: '#form-hs',
        });
      }
    });
  }, []);

  return (
    <Layout seo={seoRules && seoRules[0] && seoRules[0].seo}>
      <ContattiWrapper>
        <div
          data-scroll-section
          data-scroll-id="contactPage"
          data-scroll
          className="container mx-auto"
        >
          <div className="section-header">
            <div className="title">
              <ResponsiveText type="bigger">
                <h1>{t('contacts')}</h1>
              </ResponsiveText>
            </div>
          </div>
          <div className="section-content contatti" style={{ fontSize: 32 }}>
            {featuredImage && (
              <div className="featured-image hover-img">
                <GatsbyImage image={getImage(featuredImage.imageFile)} alt="" />
              </div>
            )}
            <ul className="infos">
              {city && <li className="city">{city}</li>}
              {address && (
                <li className="address">
                  <a href={addressUrl} target="_blank" rel="nofollow">
                    {address}
                  </a>
                </li>
              )}
              {email && (
                <li className="email">
                  <a href={`mailto:${email}`} target="_blank" rel="nofollow">
                    {email}
                  </a>
                </li>
              )}
              {phone && (
                <li className="phone">
                  <a href={`tel:${phone}`} target="_blank" rel="nofollow">
                    {phone}
                  </a>
                </li>
              )}
              {linkedinUrl && (
                <li className="linkedin">
                  <a href={linkedinUrl} target="_blank" rel="nofollow">
                    Linkedin
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div
          className="contact-form-container"
          data-scroll-id="dropALineSection"
          data-scroll-section
          data-scroll
        >
          <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto pt-12 gap-8">
            <div className="contact-heading">
              <div>
                <h2 className="form-section-title">
                  {isLng(i18n, 'it') ? formSectionTitleIT : formSectionTitleEN}
                </h2>
                <Markdown
                  className="form-section-description"
                  content={
                    isLng(i18n, 'it')
                      ? formSectionDescriptionIT
                      : formSectionDescriptionEN
                  }
                />
              </div>
            </div>

            {/* hubspot form */}
            <div className="form-wrapper" id="form-hs"></div>
          </div>
        </div>
      </ContattiWrapper>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    backend {
      contactPage {
        email
        city
        addressUrl
        address
        formSectionTitleIT
        formSectionTitleEN
        formSectionDescriptionIT
        formSectionDescriptionEN
        linkedinUrl
        phone
        featuredImage {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      seoRules(where: { slug_eq: "/contatti/" }) {
        slug
        seo {
          title
          description
          keywords
        }
      }
    }
  }
`;
